import React from 'react';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout/Layout';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <section className='flex-grow'>
      <Helmet title={`Tags | ${title}`} />
      <div className='max-w-7xl mx-auto px-4 mt-4'>
        <div className='py-6 text-center'>
          <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
            tags
          </h1>
          <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
            all tags
          </h2>
          <h3 className='mt-4 mb-8 max-w-2xl mx-auto text-2xl font-normal text-gray-400'>
            filter blog posts by tags below
          </h3>
          <div className='text-justify'>
            <h1 className='mt-2 text-4xl font-extrabold tracking-tight text-white'>
              Tags
            </h1>
            <ul className='text-white'>
              {group.map((tag) => (
                <li
                  key={tag.fieldValue}
                  className='text-white hover:text-yellow-700'
                >
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
